/* eslint-disable max-len */
export default {
  'accessPermit.approval.changesRequested': 'Verbesserungen angefordert',
  'accessPermit.approved': 'Genehmigt',
  'accessPermit.expertise.missingRequiredExpertise': 'Mängel',
  'accessPermit.expertise.newRequirement': 'Neue Anforderung',
  'accessPermit.pending': 'Wartet',
  'accessPermit.revoked': 'Entzogen',
  'accessPermit.shortenedBy': 'Zugangsberechtigung entsprechend der Geltungsdauer der Qualifikation {0} verkürzt',
  'accessPermit.verification': 'Überprüfung der Arbeitserlaubnis',
  'connectionError': 'Verbindungsfehler',
  'contactInfo.confirmEmail': 'Geben Sie den Code ein, der Ihnen per E-Mail zugesandt wurde',
  'contactInfo.confirmInfo': 'Überprüfung der Kontaktinformationen ',
  'contactInfo.confirmPhone': 'Die Telefonnummer ist korrekt',
  'contactInfo.editEmail': 'E-Mail-Adresse bearbeiten',
  'contactInfo.editorModal': 'Dialogfenster zur Kontaktbearbeitung',
  'contactInfo.editPhone': 'Telefonnummer bearbeiten',
  'contactInfo.emailVerified': 'E-Mail-Adresse bestätigt',
  'contactInfo.enterCodeFromYourEmail': 'Geben Sie den Code ein, der Ihnen per E-Mail zugesandt wurde',
  'contactInfo.enterCodeFromYourPhone': 'Geben Sie den Code ein, den Sie per E-Mail erhalten haben',
  'contactInfo.isAddressCorrect': 'Ist dies Ihre E-Mail-Adresse?',
  'contactInfo.isAddressStillCorrect': 'Ist dies immer noch Ihre E-Mail-Adresse?',
  'contactInfo.isNumberCorrect': 'Ist dies Ihre Telefonnummer?',
  'contactInfo.isNumberStillCorrect': 'Ist dies immer noch Ihre Telefonnummer?',
  'contactInfo.iWillTakeCareOfThisLater': 'Ich werde das später erledigen',
  'contactInfo.phoneVerified': 'Telefonnummer bestätigt',
  'contactInfo.verificationModal': 'Dialogfenster zur Kontaktbestätigung',
  'contactInfo.verifyEmail': 'E-Mail-Adresse bestätigen',
  'contactInfo.verifyPhone': 'Telefonnummer bestätigen',
  'contactInfo.wrongCodeTryAgain': 'Falscher Code. Bitte erneut versuchen.',
  'contactInfoStatusBadge.verified': 'Bestätigt',
  'employeecheckin.areas.checkIn': 'Am Arbeitsstandort einstempeln',
  'employeecheckin.loadWorkTimeReports': 'Gesamtarbeitszeit aller Arbeitsstandorte anzeigen',
  'employeecheckin.noAccessPermits': 'Sie haben keine Zugangsberechtigung für den Arbeitsstandort',
  'employeeMenu.accessPermits': 'Zugangsberechtigungen',
  'employeeMenu.checkInsHistory': 'Stempel-Verlauf',
  'employeeMenu.competences': 'Qualifikationen',
  'expertise.pendingTraining': 'Abzulegende Schulung',
  'expertise.pendingTrainings': 'Abzulegende Schulungen',
  'footer.call': 'Anrufen',
  'footer.or': 'oder',
  'footer.privacyPolicy': 'Datenschutzerklärung',
  'footer.support': 'Support:',
  'invoiceHours.employee.summary': 'Verlauf der Zuweisung von Arbeitsstunden zu Arbeitsaufgaben',
  'label.a1CertificateExpired': 'Gültigkeit der A1-Bescheinigung abgelaufen',
  'label.accessPermitRejected': 'Abgelehnt',
  'label.accessPermitValid': 'Zugangsberechtigung für den Arbeitsstandort gültig',
  'label.addCommentToCheckIn': 'Kommentar zum Einstempeln hinzufügen',
  'label.addCommentToCheckOut': 'Kommentar zum Ausstempeln hinzufügen',
  'label.allRequiredFieldsValid': 'Alle Pflichtangaben ausgefüllt',
  'label.area': 'Arbeitsstandort  ',
  'label.atTime': 'Uhrzeit:',
  'label.begins': 'Beginnt ',
  'label.checkInAlreadyExists': 'Sie sind bereits eingestempelt',
  'label.checkOutForgottenHeading': 'Ausstempeln nicht erfolgt',
  'label.closeWithoutSaving': 'Schließen ohne zu speichern',
  'label.comment': 'Kommentar',
  'label.commentOrReimbursment': 'Kommentar / Auslagenerstattung',
  'label.company': 'Unternehmen',
  'label.competences': 'Qualifikationen',
  'label.dashboard': 'Startseite',
  'label.default': 'Vorgabe',
  'label.edit': 'Bearbeiten',
  'label.employeeInfo': 'Persönliche Angaben',
  'label.ended': 'Abgeschlossen',
  'label.endTask': 'Beenden <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Grund für Krankschreibung angeben',
  'label.enterUsernameFirst': 'Geben Sie zuerst die E-Mail ein',
  'label.geolocationFailed': 'Standortbestimmung fehlgeschlagen. Der Stempelung wird ein Vermerk beigefügt.',
  'label.hide': 'Verbergen',
  'label.in': 'Hinein',
  'label.introduction': 'Einarbeitung',
  'label.invoiceHours': 'Verteilung der Arbeitszeit',
  'label.lastCheckOut': 'Vorheriges Ausstempeln',
  'label.lastWeek': 'Letzte Woche',
  'label.logout': 'Abmelden',
  'label.mobile.home': 'Startseite',
  'label.no': 'Nein',
  'label.notes': 'Weitere Informationen',
  'label.out': 'Heraus',
  'label.outsideSite': 'Sie befinden sich außerhalb des Arbeitsstandortes. Ihrer Stempelung wird ein Vermerk beigefügt.',
  'label.password': 'Wachtwoord',
  'label.ready': 'Fertig!',
  'label.required': 'Pflichtangabe',
  'label.residencePermitExpired': 'Aufenthaltserlaubnis abgelaufen',
  'label.safety': 'Sicherheit',
  'label.save': 'Speichern',
  'label.saving': 'Wird gespeichert...',
  'label.selectEndDate': 'Ablaufdatum wählen',
  'label.sending': 'Wird gesendet...',
  'label.sendPasswordInSMS': 'Passwort senden',
  'label.show': 'Zeigen',
  'label.startTask': 'Beginnen <strong>{0}</strong>',
  'label.thisWeek': 'Laufende Woche',
  'label.travelExpenseClaims': 'Reisekostenerstattungen',
  'label.usernameShort': 'Nutzer-ID',
  'label.visitor': 'Gast',
  'label.workHourBalance': 'Arbeitszeitsaldo',
  'label.workingHoursCombined': 'Gesamtzahl der Arbeitsstunden für alle Arbeitsplätze',
  'label.workingTime': 'Arbeitszeit',
  'label.yes': 'Ja',
  'login.enterAccountAndNumber': 'Nutzer-ID eingeben',
  'login.incorrectRequest': 'Fehlerhafte Anfrage!',
  'login.infoMessage': 'Wir senden Ihnen ein neues Passwort an Ihre Mobilnummer',
  'login.login': 'Anmelden',
  'login.newPasswordSentToTelephone': 'Ihr neues Passwort wurde an Ihre Telefonnummer geschickt',
  'login.ohNoIe': '<p>Sie verwenden einen <strong>Internet Explorer</strong> Browser, der von Zeroni nicht mehr unterstützt wird. Unterstützte Browser sind <a href=\'https://www.google.com/chrome/\'>Chrome</a>, <a href=\'https://www.getfirefox.com/\'>Firefox</a>, <a href=\'https://www.opera.com/\'>Opera</a>, <a href=\'https://www.microsoft.com/fi-fi/windows/microsoft-edge/\'>Edge</a> und <a href=\'https://www.apple.com/safari/\'>Safari</a>.</p>',
  'login.passwordForgotten': 'Passwort vergessen?',
  'login.requestNewPassword': 'Neues Passwort anfordern',
  'login.returnToLogin': 'Zurück zur Anmeldeseite',
  'login.timeout': 'Zeitüberschreitung bei Anmeldung: Es hat zu lange gedauert!',
  'login.userHasSsoUsername': '<p>Ihr Unternehmen hat einen eigenen Anmeldedienst, <strong>Azure AD</strong>.</p><p> Melden Sie sich bei Zeroni mit Ihrem AD-Dienst an.</p>',
  'login.wrongPassword': 'Falsche E-Mail-Adresse oder falsches Passwort',
  'login.wrongVerificationCode': 'Der Bestätigungscode ist nicht korrekt!',
  'mainHelp.call': 'Anrufen',
  'mainHelp.email': 'Nachricht senden',
  'mainHelp.help.desc': 'Tipps, Ratschläge, häufig gestellte Fragen.',
  'mainHelp.help.heading': 'Gebrauchshinweise ',
  'mainHelp.privacyPolicy': 'Datenschutzerklärung',
  'mainHelp.resources': 'Ressourcen',
  'mainHelp.support': 'Support',
  'mainHelp.telephone.hours': 'werktags 8–16 Uhr',
  'mainHelp.telephone.price': '0,69 €/min',
  'message.addOtherNotesViaCheckInList': 'Weitere Kommentare und Auslagenerstattungen eingeben, indem Sie den <a href=\'{0}\'>Stempel-Verlauf</a> durchsuchen',
  'message.checkoutForgottenBody': '<p>Sie haben sich am <strong>{0}</strong> Arbeitsstandort <strong>{1}</strong> eingestempelt, aber die Ausstempelung steht noch aus.</p><p> Bitte geben Sie die Uhrzeit ein, zu der Sie den Arbeitsstandort verlassen haben.</p>',
  'message.firstLoginNoPassword': '<p><strong>Hallo, {0}!</strong></p><p>Sie haben noch kein Passwort für Zeroni. Senden Sie es sich selbst zu.</p>',
  'message.passwordSent': '<strong>Das Passwort wurde an Ihr Mobiltelefon gesendet.</strong> Sie können sich jetzt anmelden.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> kann mehrere Tage in Anspruch nehmen. Wählen Sie das Enddatum.',
  'message.sessionExpired': '<strong>Sie waren zu lange inaktiv</strong> und wurden deshalb ausgeloggt. Bitte melden Sie sich erneut an.',
  'message.sickLeaveReason': 'Bitte geben Sie den Grund an, warum Sie krankgeschrieben sind.',
  'message.unknownUsername': '<strong>Zeroni erkennt die von Ihnen eingegebene ID nicht.</strong> Bitte überprüfen Sie die ID.',
  'more': 'Hinzufügen',
  'password.changePassword': 'Passwort ändern',
  'password.changing': 'Passwort wird geändert...',
  'password.invalidPasswordMessage': '<strong> Falsches Passwort</strong>: Überprüfen Sie Ihr altes Passwort und versuchen Sie es erneut.',
  'password.newPassword': 'Neues Passwort',
  'password.oldPassword': 'Altes Passwort',
  'quality.quality': 'Qualität',
  'topMenu.loginAsCompany': 'Als Unternehmen anmelden',
  'topMenu.loginAsEmployee': 'Als Mitarbeiter anmelden',
  'user.session': 'Sitzung',
  'userEditor.onlyUserCanChange': 'Bearbeitung nur durch die Person selbst möglich',
  'userSettings.changePassword': 'Passwort wechseln',
  'userSettings.defaultCompanyAndSite': 'Vorgabe für Unternehmen und Arbeitsstandort',
  'userSettings.defaultSite': 'Vorgabe für Arbeitsstandort',
  'userSettings': 'Benutzereinstellungen',
  'validator.errors.emailFormat': 'Falsche E-Mail-Adresse.',
  'validator.errors.emailNotUnique': 'Diese Adresse wird bereits verwendet',
  'validator.errors.phoneFormat': 'Falsche Telefonnummer',
  'validator.errors.phoneNotUnique': 'Diese Nummer wird bereits verwendet',
  'validator.errors.required': 'Pflichtangabe',
  'validator.valueNotChanged': 'Der Wert hat sich nicht geändert',
};
