/* eslint-disable max-len */
export default {
  'accessPermit.approval.changesRequested': 'Poproszono o poprawki',
  'accessPermit.approved': 'Zatwierdzono',
  'accessPermit.expertise.missingRequiredExpertise': 'Niedociągnięcia',
  'accessPermit.expertise.newRequirement': 'Nowe wymaganie',
  'accessPermit.pending': 'Oczekuje',
  'accessPermit.revoked': 'Anulowane',
  'accessPermit.shortenedBy': 'Zezwolenie na pracę na terenie budowy skrócone zgodnie z okresem ważności {0}',
  'accessPermit.verification': 'Kontrola prawa do pracy',
  'connectionError': 'Błąd połączenia',
  'contactInfo.confirmEmail': 'Wprowadź kod wysłany na Twój adres e-mail',
  'contactInfo.confirmInfo': 'Sprawdzanie danych kontaktowych',
  'contactInfo.confirmPhone': 'Numer jest poprawny',
  'contactInfo.editEmail': 'Edytuj wiadomość e-mail',
  'contactInfo.editorModal': 'Okno dialogowe edycji kontaktu',
  'contactInfo.editPhone': 'Edytuj numer telefonu',
  'contactInfo.emailVerified': 'Email potwierdzony',
  'contactInfo.enterCodeFromYourEmail': 'Wprowadź kod wysłany na Twój adres e-mail',
  'contactInfo.enterCodeFromYourPhone': 'Wprowadź kod wysłany przez SMS',
  'contactInfo.isAddressCorrect': 'Czy to jest twój adres?',
  'contactInfo.isAddressStillCorrect': 'Czy to jest nadal twój adres?',
  'contactInfo.isNumberCorrect': 'Czy to jest twój numer?',
  'contactInfo.isNumberStillCorrect': 'Czy to nadal twój numer?',
  'contactInfo.iWillTakeCareOfThisLater': 'Zajmę się tym później',
  'contactInfo.phoneVerified': 'Telefon potwierdzony',
  'contactInfo.verificationModal': 'Okno dialogowe potwierdzenia kontaktu',
  'contactInfo.verifyEmail': 'Potwierdź adres poczty elektronicznej',
  'contactInfo.verifyPhone': 'Potwierdź numer telefonu',
  'contactInfo.wrongCodeTryAgain': 'Niewłaściwy kod. Spróbuj ponownie.',
  'contactInfoStatusBadge.verified': 'Zatwierdzone',
  'employeecheckin.areas.checkIn': 'Rejestracja uprawniająca do wstępu na teren',
  'employeecheckin.loadWorkTimeReports': 'Pokaż łączną liczbę przepracowanych godzin dla wszystkich terenów budowy',
  'employeecheckin.noAccessPermits': 'Nie masz żadnego zezwolenia na wstęp na teren budowy',
  'employeeMenu.accessPermits': 'Zezwolenia na wstęp na teren budowy',
  'employeeMenu.checkInsHistory': 'Historia rejestracji',
  'employeeMenu.competences': 'Kwalifikacje',
  'expertise.pendingTraining': 'Szkolenie do ukończenia',
  'expertise.pendingTrainings': 'Szkolenia do ukończenia',
  'footer.call': 'Dzwoń',
  'footer.or': 'lub',
  'footer.privacyPolicy': 'Zasady ochrony danych',
  'footer.support': 'Wsparcie:',
  'invoiceHours.employee.summary': 'Historia potwierdzeń godzinowych',
  'label.a1CertificateExpired': 'Certyfikat A1 wygasł',
  'label.accessPermitRejected': 'Odrzucony',
  'label.accessPermitValid': 'Zezwolenie na wstęp na teren budowy jest ważne',
  'label.addCommentToCheckIn': 'Dodaj komentarz dotyczący rejestracji wejścia',
  'label.addCommentToCheckOut': 'Dodaj komentarz dotyczący rejestracji wyjścia',
  'label.allRequiredFieldsValid': 'Wypełniono wszystkie obowiązkowe informacje',
  'label.area': 'Teren budowy',
  'label.atTime': 'godzina',
  'label.begins': 'Początek',
  'label.checkInAlreadyExists': 'Zostałeś już raz zarejestrowany',
  'label.checkOutForgottenHeading': 'Nie dokonano rejestracji wyjścia',
  'label.closeWithoutSaving': 'Zamknij bez zapisywania',
  'label.comment': 'Komentarz',
  'label.commentOrReimbursment': 'Komentarz / zwrot kosztów',
  'label.company': 'Firma',
  'label.competences': 'Kwalifikacje',
  'label.dashboard': 'Strona główna',
  'label.default': 'Domyślny',
  'label.edit': 'Zapisz',
  'label.employeeInfo': 'Dane osobowe',
  'label.ended': 'Wygasło',
  'label.endTask': 'Zakończ <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Wprowadź powód zwolnienia lekarskiego',
  'label.enterUsernameFirst': 'Najpierw wprowadź e-mail',
  'label.geolocationFailed': 'Lokalizacja nie powiodła się. Do podbitych rejestracji dołączona jest notatka.',
  'label.hide': 'Ukryj',
  'label.in': 'Wejdź',
  'label.introduction': 'Wdrożenie',
  'label.invoiceHours': 'Potwierdzenie godzinowe',
  'label.lastCheckOut': 'Poprzednia rejestracja wyjścia',
  'label.lastWeek': 'Ostatni tydzień',
  'label.logout': 'Wyloguj się',
  'label.mobile.home': 'Strona główna',
  'label.no': 'Nie',
  'label.notes': 'Informacje dodatkowe',
  'label.out': 'Wyjście',
  'label.outsideSite': 'Znajdujesz się poza terenem budowy. Do rejestracji dołączona jest notatka.',
  'label.password': 'Hasło',
  'label.ready': 'Gotowe!',
  'label.required': 'Informacja obowiązkowa',
  'label.residencePermitExpired': 'Zezwolenie na pobyt wygasło',
  'label.safety': 'Bezpieczeństwo',
  'label.save': 'Zapisz',
  'label.saving': 'Zapisywanie...',
  'label.selectEndDate': 'Wybierz datę wygaśnięcia',
  'label.sending': 'Wysyłanie...',
  'label.sendPasswordInSMS': 'Wyślij hasło',
  'label.show': 'Pokaż',
  'label.startTask': 'Rozpocznij <strong>{0}</strong>',
  'label.thisWeek': 'Bieżący tydzień',
  'label.travelExpenseClaims': 'Zwrot kosztów podróży',
  'label.usernameShort': 'Nazwa użytkownika',
  'label.visitor': 'Gość',
  'label.workHourBalance': 'Zakumulowany czas pracy',
  'label.workingHoursCombined': 'Łączna liczba przepracowanych godzin na wszystkich terenach budowy',
  'label.workingTime': 'Czas pracy',
  'label.yes': 'Tak',
  'login.enterAccountAndNumber': 'Wprowadź swoją nazwę użytkownika',
  'login.incorrectRequest': 'Nieprawidłowe żądanie!',
  'login.infoMessage': 'Wyślemy nowe hasło na Twój numer telefonu komórkowego',
  'login.login': 'Zaloguj się',
  'login.newPasswordSentToTelephone': 'Nowe hasło zostało wysłane na Twój numer telefonu',
  'login.ohNoIe': '<p>Korzystasz z przeglądarki <strong>Internet Explorer</strong>, która nie jest już obsługiwana przez Zeroni. Obsługiwane przeglądarki to <a href=\'https://www.google.com/chrome/\'>Chrome</a>, <a href=\'https://www.getfirefox.com/\'>Firefox</a>, <a href=\'https://www.opera.com/\'>Opera</a>, <a href=\'https://www.microsoft.com/fi-fi/windows/microsoft-edge/\'>Edge</a> i <a href=\'https://www.apple.com/safari/\'>Safari</a>.</p>',
  'login.passwordForgotten': 'Zapomniałeś hasła?',
  'login.requestNewPassword': 'Poproś o nowe hasło',
  'login.returnToLogin': 'Wróć do strony logowania',
  'login.timeout': 'Limit czasu logowania: minęło zbyt dużo czasu!',
  'login.userHasSsoUsername': '<p>Twoja firma ma oddzielną usługę logowania, <strong>Azure AD</strong>.</p><p>Zaloguj się do Zeroni za pomocą usługi AD.</p>',
  'login.wrongPassword': 'Nieprawidłowy adres e-mail lub hasło',
  'login.wrongVerificationCode': 'Kod potwierdzający jest nieprawidłowy!',
  'mainHelp.call': 'Zadzwoń',
  'mainHelp.email': 'Wyślij wiadomość',
  'mainHelp.help.desc': 'Wskazówki, porady, często zadawane pytania.',
  'mainHelp.help.heading': 'Instrukcja użytkowania',
  'mainHelp.privacyPolicy': 'Zasady ochrony danych',
  'mainHelp.resources': 'Zasoby',
  'mainHelp.support': 'Wsparcie',
  'mainHelp.telephone.hours': 'w dni powszednie od 8 do 16',
  'mainHelp.telephone.price': '0,69 €/min',
  'message.addOtherNotesViaCheckInList': 'Dodaj inne komentarze i zwroty kosztów przeglądając <a href=\'{0}\'>historię rejestracji</a>',
  'message.checkoutForgottenBody': '<p>Zarejestrowałeś wejście<strong>{0}</strong> na teren budowy <strong>{1}</strong>, ale nadal nie zostało zarejestrowane wyjście.</p><p>Podaj czas opuszczenia terenu budowy.</p>',
  'message.firstLoginNoPassword': '<p><strong>Witaj, {0}!</strong></p><p>Nie masz jeszcze hasła do Zeroni. Wyślij je do siebie SMS-em.</p>',
  'message.passwordSent': '<strong>Hasło zostało wysłane na Twój telefon komórkowy.</strong> Możesz się teraz zalogować.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> może potrwać kilka dni. Wybierz datę wygaśnięcia.',
  'message.sessionExpired': '<strong>Byłeś nieaktywny zbyt długo</strong> i dlatego zostałeś wylogowany. Zaloguj się ponownie.',
  'message.sickLeaveReason': 'Podaj powód, dla którego jesteś na zwolnieniu lekarskim.',
  'message.unknownUsername': '<strong>Zeroni nie rozpoznaje wprowadzonego identyfikatora.</strong> Sprawdź swoją nazwę użytkownika.',
  'more': 'Dodaj',
  'password.changePassword': 'Zmień hasło',
  'password.changing': 'Zmiana w toku...',
  'password.invalidPasswordMessage': '<strong>Nieprawidłowe hasło</strong>: Sprawdź stare hasło i spróbuj ponownie.',
  'password.newPassword': 'Nowe hasło',
  'password.oldPassword': 'Stare hasło',
  'quality.quality': 'Jakość',
  'topMenu.loginAsCompany': 'Zarejestruj się jako firma',
  'topMenu.loginAsEmployee': 'Zaloguj się jako pracownik',
  'user.session': 'Sesja',
  'userEditor.onlyUserCanChange': 'Dana osoba może to edytować tylko sama',
  'userSettings.changePassword': 'Zmień hasło',
  'userSettings.defaultCompanyAndSite': 'Domyślna firma i kraj pracy',
  'userSettings.defaultSite': 'Domyślny kraj pracy',
  'userSettings': 'Preferencje użytkownika',
  'validator.errors.emailFormat': 'Błędny adres poczty elektronicznej',
  'validator.errors.emailNotUnique': 'Ktoś inny już używa tego adresu',
  'validator.errors.phoneFormat': 'Nieprawidłowy numer telefonu',
  'validator.errors.phoneNotUnique': 'Ktoś inny już używa tego numeru telefonu',
  'validator.errors.required': 'Informacja obowiązkowa',
  'validator.valueNotChanged': 'Wartość nie uległa zmianie',
};
